import React from "react";

const LoadingComponent = () => {
  return (
    <div className="center">
      <div className="sppiner" />
    </div>
  );
};

export default LoadingComponent;
