import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import MethodGet from "../../config/service";
import { useTranslation } from "react-i18next";
const RolesSelect = (props) => {
  const { t } = useTranslation();
  const array = [
    { name: t("inquilino"), value: 4 },
    { name: t("asesor"), value: 3 },
    { name: t("inmobiliaria"), value: 2 },
  ];

  const detectarCambiosRole = (value) => {
    // setSelectedRole(value.target.value);
    props.detectarCambiosRole(value.target.value);
  };

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="roles-options-label">{t("soy")}</InputLabel>
        <Select
          required
          labelId="roles-options-label"
          id="roles-options-select"
          onChange={detectarCambiosRole}
          label={t("soy")}
          name="account"
        >
          {array.map((rol, index) => (
            <MenuItem key={index} value={rol.value}>
              {rol.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default RolesSelect;
